<template>
  <div>
    <sub-head v-bind:subTitle="content['인지평가']"></sub-head>
    <div>
      <div class="page_padding">
        <div class="p-relative paddingT text-center">
          <span class="font41 line-height3 word_break title text333"
            >시니어의 인지력을 평가하기 위한 <br />기능성 게임
            <span class="textPink">'그때 그시절 여름방학 이야기'</span>
          </span>
        </div>
        <div class="verticalSpacer-half"></div>
        <div class="text-center">
          <span class="font18 word_break sub-content"
            >기존의 딱딱하고 지루한 인지평가 방식을 벗어나 재미있고 친근하게
            인지평가를 할 수 있는 기능성 게임
          </span>
        </div>
      </div>
      <div class="verticalSpacer-big"></div>
      <div class="page_padding p-relative background-page">
        <div
          style="position:absolute; bottom:0; left:20%; right:20%; width:60%"
        >
          <div style="display:flex; justify-content: space-between">
            <div style="width: 48%">
              <image-component
                :is_jpg="false"
                path=""
                name="cognitive_img01"
              ></image-component>
            </div>
            <div style="width: 48%">
              <image-component
                :is_jpg="false"
                path=""
                name="cognitive_img02"
              ></image-component>
            </div>
          </div>
          <div class="verticalSpacer-half"></div>
          <div style="display:flex; justify-content: space-between">
            <div style="width: 32%">
              <image-component
                :is_jpg="false"
                path=""
                name="cognitive_img03"
              ></image-component>
            </div>
            <div style="width: 32%">
              <image-component
                :is_jpg="false"
                path=""
                name="cognitive_img04"
              ></image-component>
            </div>
            <div style="width: 32%">
              <image-component
                :is_jpg="false"
                path=""
                name="cognitive_img05"
              ></image-component>
            </div>
          </div>
          <div class="verticalSpacer"></div>
        </div>
      </div>
      <div class="page_padding">
        <div class="p-relative paddingT text-center">
          <span class="font41 line-height3 word_break title text333"
            >올바른 인지훈련을 위해서는 <br />
            <span class="textPink">현재의 인지능력을 정확히 평가</span>해야
            합니다.
          </span>
        </div>
        <div class="verticalSpacer"></div>
        <div class="cognitive">
          <div
            class="info-block"
            :class="[
              {'d-flex': index % 2 == 0},
              {'d-flex-reverse': index % 2 == 1},
            ]"
            v-for="(item, index) in cognitiveContents"
            :key="index"
          >
            <div>
              <image-component
                :is_jpg="false"
                path=""
                :name="item.path"
              ></image-component>
            </div>
            <div class="cognitive-content-wrapper">
              <div class="text-left font34">
                <span class="fontBold word_break">{{ item.headline }}</span>
              </div>
              <div class="verticalSpacer-half"></div>
              <div class="text-left">
                <span class="fontS word_break sub-content">{{
                  item.content
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page_padding" style="background-color: #F3F3F3">
        <div class="p-relative paddingT text-center">
          <span class="fontBold font41 line-height3 word_break title text333"
            >인지평가 <span class="fontBold textPink">데이터 및 관리</span>
          </span>
        </div>
        <div class="verticalSpacer-half"></div>
        <div class="text-center">
          <span class="font18 word_break sub-content"
            >인지평가 게임의 플레이 데이터를 수집, 분석하여 상세한 결과를
            사용자에게 제공합니다.
          </span>
        </div>
        <div class="verticalSpacer-half"></div>
        <div class="cognitive-img-container">
          <div class="cognitive-img-wrapper">
            <image-component
              :is_jpg="false"
              name="cognitive_img09"
            ></image-component>
          </div>
          <div class="cognitive-img-wrapper">
            <image-component
              :is_jpg="false"
              name="cognitive_img10"
            ></image-component>
          </div>
        </div>
        <div class="verticalSpacer"></div>
        <div>
          <div class="cognitive-item-wrapper">
            <div
              class="cognitive-item"
              v-for="(item, index) in cognitiveDataManagement"
              :key="index"
            >
              <div class="icon-wrapper">
                <image-component
                  :is_jpg="false"
                  :name="item.path"
                ></image-component>
              </div>
              <div class="content-wrapper">
                <div class="content-header">
                  <span class="font24 fontExtraBold word_break">{{
                    item.headline
                  }}</span>
                </div>
                <div class="content-main">
                  <span class="fontS word_break sub-content">{{
                    item.content
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="verticalSpacer"></div>
        <div class="">
          <div class="cognitive-info-wrapper">
            <span class="content fontS">
              ※ 본 프로그램은 2021년 게임콘텐츠 제작지원 사업의 도움으로
              제작되었습니다.</span
            >
          </div>
          <div class="verticalSpacer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subTopContents from "../assets/source/json/sub_top.json";
import CognitiveContents from "../assets/source/json/happytable_cognitiveGame.json";
import CognitiveDataManagement from "../assets/source/json/cognitive_data_management.json";
import ImageComponent from "../components/imageComponent.vue";
import SubHead from "../components/sub_head.vue";

export default {
  components: {
    SubHead,
    ImageComponent,
  },
  data() {
    return {
      content: subTopContents,
      cognitiveContents: CognitiveContents,
      cognitiveDataManagement: CognitiveDataManagement,
    };
  },
  methods: {
    getImgSrc(src, path) {
      var images;
      if (path == "") {
        src = "happytable_product" + (src + 2);
        images = require.context("../assets/source/images/", false, /\.jpg$/);
        return images("./" + src + ".jpg");
      } else if (path == "modal_product") {
        images = require.context("../assets/source/images/", false, /\.jpg$/);
        return images("./" + src + ".jpg");
      } else {
        images = require.context(
          "../assets/source/images/screenshots",
          false,
          /\.jpg$/
        );
        return images("./" + src + ".jpg");
      }
    },
  },
};
</script>

<style scoped>
.sub-content {
  color: #646464;
}
.verticalSpacer-big {
  width: 100%;
  height: 17vw;
  margin: 0 auto;
}
.background-page {
  background-color: #f2f4f6;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 480px; */
  height: 28vw;
}
.d-flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.cognitive .info-block {
  padding: 2rem;
  box-shadow: 0px 0px 30px #00000024;
  border-radius: 1rem;
  margin-bottom: 5rem;
}
.cognitive-content-wrapper {
  padding: 1rem 2rem;
}
.cognitive-img-container {
  display: flex;
  justify-content: space-between;
}
.cognitive-img-wrapper {
  width: 48%;
}
.cognitive-item-wrapper {
  display: flex;
}
.cognitive-item {
  width: 25%;
  border-right: 1px solid #e7e7e7;
}
.cognitive-item:last-child {
  border: none !important;
}
.icon-wrapper {
  width: 30%;
  margin: 0 auto;
}
.content-wrapper {
  padding: 1rem 1rem 2rem 1rem;
}
.content-header {
  margin-bottom: 1rem;
}
.cognitive-info-wrapper {
  background-color: #e7e7e7;
  padding: 1rem 0;
}
.cognitive-info-wrapper .content {
  color: #dc375d;
}
@media (max-width: 767px) {
  .verticalSpacer-big {
    width: 100%;
    height: 20vw;
    margin: 0 auto;
  }
  .paddingT {
    padding-top: 60px;
  }
  .cognitive .info-block {
    margin-bottom: 2rem;
  }
  .cognitive-content-wrapper {
    padding: 1rem 0rem;
  }
  .cognitive .d-flex,
  .cognitive .d-flex-reverse {
    flex-direction: column;
  }
  .cognitive-img-container {
    display: block;
    justify-content: space-between;
  }
  .cognitive-img-wrapper {
    width: 100%;
    margin: 1rem 0;
  }
  .cognitive-item-wrapper {
    display: block;
  }
  .cognitive-item {
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
  }
  .icon-wrapper {
    width: 20%;
    margin: 0 auto;
  }
  .content-wrapper {
    padding: 1rem 1rem 2rem 1rem;
    width: 70%;
  }
  .content-header,
  .content-main {
    text-align: left;
  }
}
</style>
